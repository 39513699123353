/* Space Theme CSS Enhancements */

/* Global typography settings for consistent letter spacing */
body {
  letter-spacing: 0.5px;
}

.MuiTypography-root {
  letter-spacing: 0.5px !important;
}

.MuiButton-root {
  letter-spacing: 1px !important;
}

/* Add enhanced glow to buttons */
.MuiButton-containedPrimary {
  position: relative;
  overflow: hidden;
}

.MuiButton-containedPrimary::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(33, 150, 243, 0) 0%,
    rgba(33, 150, 243, 0.2) 50%,
    rgba(33, 150, 243, 0) 100%
  );
  transform: rotate(30deg);
  animation: glowShift 6s infinite linear;
}

.MuiButton-containedSecondary::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(0, 229, 255, 0) 0%,
    rgba(0, 229, 255, 0.2) 50%,
    rgba(0, 229, 255, 0) 100%
  );
  transform: rotate(30deg);
  animation: glowShift 6s infinite linear;
}

@keyframes glowShift {
  0% {
    transform: rotate(30deg) translateX(-30%);
  }
  50% {
    transform: rotate(30deg) translateX(30%);
  }
  100% {
    transform: rotate(30deg) translateX(-30%);
  }
}

/* Add subtle star background to dark mode */
body.dark-mode {
  background-image: 
    radial-gradient(1px 1px at 25px 5px, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0)),
    radial-gradient(1px 1px at 50px 25px, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0)),
    radial-gradient(1px 1px at 125px 20px, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 70px 70px, rgba(0, 229, 255, 0.1), rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 160px 120px, rgba(255, 0, 255, 0.1), rgba(0, 0, 0, 0));
  background-repeat: repeat;
  background-size: 200px 200px;
}

/* Add subtle cyber grid to some components */
.cyber-grid {
  position: relative;
}

.cyber-grid::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(90deg, rgba(0, 229, 255, 0.03) 1px, transparent 1px),
    linear-gradient(0deg, rgba(0, 229, 255, 0.03) 1px, transparent 1px);
  background-size: 20px 20px;
  pointer-events: none;
  z-index: 0;
}

/* Neon text effect for headings */
.neon-text {
  text-shadow: 0 0 5px rgba(0, 229, 255, 0.5), 0 0 10px rgba(0, 229, 255, 0.3);
}

.neon-text-magenta {
  text-shadow: 0 0 5px rgba(255, 0, 255, 0.5), 0 0 10px rgba(255, 0, 255, 0.3);
}

/* Add subtle hover effect to cards */
.MuiCard-root {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.MuiCard-root:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 0 15px rgba(0, 229, 255, 0.2);
}

/* Add pulse effect to primary buttons */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(33, 150, 243, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0);
  }
}

.MuiButton-containedPrimary:hover {
  animation: pulse 1.5s infinite;
}

/* Add pulse effect to secondary buttons */
@keyframes pulseSecondary {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 229, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 229, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 229, 255, 0);
  }
}

.MuiButton-containedSecondary:hover {
  animation: pulseSecondary 1.5s infinite;
}

/* Pulsing brightness effect for the N letter lines */
@keyframes nLinePulse {
  0% {
    opacity: 0.5;
    stroke-width: 2;
    filter: drop-shadow(0 0 2px rgba(0, 229, 255, 0.5));
  }
  50% {
    opacity: 1;
    stroke-width: 3;
    filter: drop-shadow(0 0 5px rgba(0, 229, 255, 0.9)) drop-shadow(0 0 10px rgba(0, 229, 255, 0.5));
  }
  100% {
    opacity: 0.5;
    stroke-width: 2;
    filter: drop-shadow(0 0 2px rgba(0, 229, 255, 0.5));
  }
}

svg line.n-line {
  animation: nLinePulse 3s infinite;
}

/* Different timing for each line to create a flowing effect */
svg line.n-line-1 {
  animation-delay: 0s;
}

svg line.n-line-2 {
  animation-delay: 0.5s;
}

svg line.n-line-3 {
  animation-delay: 1s;
}

svg line.n-line-4 {
  animation-delay: 1.5s;
}

/* N letter animations use the same starPulse animation as the shield dots */
